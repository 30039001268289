<template>
	<div>
		<div style="position: relative">
			<v-autocomplete
					v-model="new_val"
					:background-color="background"
					:disabled="readonly"
					:height="30"
					:item-text="itemText ? itemText : 'display_name'"
					:item-value="itemVal"
					:items="items"
					:label="label"
					:menu-props="{offsetY: true, rounded: 'lg', contentClass: 'sub-menu-custom'}"
					:multiple="multiple"
					:no-data-text="$t('single_trad.Table.no_data')"
					:return-object="!returnValue"
					:type="type"
					@change="$emit('change'); focus = false"
					class="rounded-lg paragraph"
					dense
					:attach="attach"
					:auto="attach"
					hide-details
					outlined
					@focus="focus = true"
					@blur="focus = false"
					@update:search-input="(e) => lazySearch = e"
			>
				<template v-slot:append>
					<v-icon v-if="clearable && new_val !== '' && new_val !== null && typeof new_val !== 'undefined' && new_val.length !== 0" size="18" @click="new_val = null; $emit('change')" style="position: relative; bottom: 3px; right: -3px;">mdi-close</v-icon>
					<v-icon v-if="appendIcon" size="20" class="ml-1" @click="iconClick" style="position: relative; bottom: 3px;">{{ appendIcon }}</v-icon>
					<v-icon v-if="!noMenuIcon" size="20" style="position: relative; bottom: 3px; right: -6px">mdi-menu-down</v-icon>
				</template>

				<template v-slot:selection="{item, index}">
					<div
							v-if="new_val.length === 1 && multiple && !focus"
							class="transition-cubic paragraph text-truncate"
					>
						{{ typeof itemText !== 'undefined' ? itemText === '' ? $options.filters.firstCapital(item) : $options.filters.firstCapital(item[itemText]) : $options.filters.firstCapital(item.display_name) }}
					</div>
					<div v-else-if="multiple && index === 0 && (lazySearch !== null || lazySearch !== '') && !focus" class="paragraph">{{ new_val.length + ' ' + $t('single_trad.Table.selected')}}</div>
					<div
							v-else-if="index === 1 && multiple"
							class="transition-cubic paragraph text-truncate"
					>
						{{ $options.filters.firstCapital($tc('single_trad', new_val.length , {length: new_val.length})) }}
					</div>
					<div
							v-else-if="!multiple && !focus"
							class="transition-cubic text-truncate paragraph"
							style="max-width: 100%"
					>
						{{ typeof itemText !== 'undefined' ? itemText ? $options.filters.firstCapital(item[itemText]) : $options.filters.firstCapital(item) : $options.filters.firstCapital(item.display_name) }}
					</div>
				</template>

				<template v-slot:label v-if="label">
					<div class="paragraph" style="margin-top: -4px;" :title="label">{{ label | firstCapital }}</div>
				</template>
			</v-autocomplete>
		</div>
	</div>
</template>

<script>
	export default {
		name: "Select",
		props: {
			value: {},
			type: {type: String},
			appendIcon: {type: String},
			clearable: {type: Boolean},
			multiple: {type: Boolean},
			readonly: {type: Boolean},
			noMenuIcon: {type: Boolean},
			returnValue: {type: Boolean},
			iconClick: {type: Function, default: () => {}},
			items: {},
			label: {type: String},
			background: {type: String, default: 'cell'},
			itemText: {type: String},
			itemVal: {type: String, default: 'id'},
			attach: { default: true}
		},
		data(){
			return {
				focus: false,
				lazySearch: ''
			}
		},
		computed: {
			new_val: {
				get(){
					return this.value
				},
				set(val){
					this.$emit('input', val)
				}
			}
		}
	}
</script>

<style scoped>

</style>
